import React, {useEffect} from "react"
import { observer } from "mobx-react-lite"
import {Spin, Button, Card, Form, Input, message, Tabs } from "antd"
import {UserOutlined, LockOutlined, TabletOutlined, MailOutlined} from '@ant-design/icons'

import { http, Backend } from  '../../utils'
import { Countdown, Loading } from '../../models'

declare const window: any

const mounted = () => {
  const { hint } = window.gon
  if (hint) {
    for(let level in hint) {
      let content = hint[level]
      if (content) message[level](content)
    }
  }
}

const loading = new Loading()

export default () => {
  useEffect(mounted, [])
  
  const LoginForm = observer(({children, url, form}) => {
    const submit = (values) => {
      loading.around(async () => {
        try {
          await http().post(url, values)
          // 重定向到之前请求的地址
          window.location.replace(Backend.root_path({ anchor: window.location.hash.replace('#', '') }))
        } catch {}
      })
    }

    return (
      <Spin spinning={loading.state}>
        <Form onFinish={submit} form={form}>
          {children}
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    )
  })

  const PasswordLogin = () => {
    return(
      <LoginForm url={Backend.session_path()}>
        <Form.Item name="id" rules={[{ required: true, message: '用户/手机号不能为空！' }]}>
          <Input size="large" placeholder="用户名/手机号" prefix={<UserOutlined style={{color: "rgba(0,0,0,.25)"}} />} />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: '密码不能为空！' }]}>
          <Input.Password size="large" placeholder="密码" autoComplete="new-password" prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}} />} />
        </Form.Item>
      </LoginForm>  
    )
  }

  const verify_code_counter = new Countdown(60)

  const SMSLogin = observer(() => {
    const [form] = Form.useForm()

    const get_login_code = async () => {
      const phone = (form.getFieldValue('phone') || "").trim()

      if (phone) {
        await http().put(Backend.sms_session_path(), { phone })
        
        verify_code_counter.countdown()
      } else {
        message.error("请先填写手机号")
      }
    }

    return(
      <LoginForm url={Backend.sms_session_path()} form={ form }>
        <Form.Item name="phone" rules={[{ required: true, message: '手机号不能为空！' }]}>
          <Input size="large" placeholder="手机号" prefix={<TabletOutlined style={{color: "rgba(0,0,0,.25)"}} />} />
        </Form.Item>
        
        <Form.Item>
          <div style={{ display: 'flex' }}>
            <Form.Item name="code" rules={[{ required: true, message: '验证码不能为空！' }]} noStyle>
              <Input size="large" placeholder="验证码" prefix={<MailOutlined style={{color: "rgba(0,0,0,.25)"}} />} />
            </Form.Item>
            <Button size="large" disabled={!!verify_code_counter.current} onClick={get_login_code} style={{ marginLeft: 10 }}>{verify_code_counter.current || '发送验证码'}</Button>
          </div>
        </Form.Item>
      </LoginForm>
    )
  })

  const items = [
    {
      key: "password",
      label: "密码登录",
      children: <PasswordLogin />
    },
    {
      key: "sms",
      label: "验证码登录",
      children: <SMSLogin />
    }
  ]

  return (
    <Card bordered={false} className="login-card">
      <Tabs centered items={items} tabBarStyle={{ color: "white", alignSelf: "center" }} />
    </Card>
  )

}