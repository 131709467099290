import { Turbo } from "@hotwired/turbo-rails"
import React from 'react'
import { createRoot } from 'react-dom/client';
import { Row, Col } from 'antd'

import 'antd/dist/reset.css'

import { Backend } from 'utils'
import Login from 'components/session/Login'

import logo from 'images/logo-with-label.png'

Turbo.start()

document.addEventListener('turbo:load', () => {
  const root = 'root' |> document.getElementById |> createRoot
  
  root.render(
    <>
      <Row justify="center" align="middle" className="login-wrap">
        <Col xs={22} sm={16} md={12} lg={6} className="login-box">
          <img src={logo} />
          <Login />
        </Col>
      </Row>
      <footer>
        <p>Copyright  2013 - 2023 Lightalk Tech. Co. Ltd., All Rights Reserved</p>
      </footer>
    </>
  )
})